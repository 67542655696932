import React, { FC } from 'react';
import { LinearProgress } from '@mui/material';
import styled from '@emotion/styled';
import Dropzone, { Accept } from 'react-dropzone';
import { UPLOAD_ATTACHMENT } from '../content.graphql';
import { useMutation } from '@apollo/client';
import { useGuideCmsContext } from '../GuideCmsContext';
import { useAttachmentsContext } from './AttachmentsContext';

export const UploadGuideAttachment: FC = () => {
  const { guideId } = useGuideCmsContext();
  const { addFile } = useAttachmentsContext();

  const [uploadAttachmentMutation, { loading: isUploading }] = useMutation(UPLOAD_ATTACHMENT);

  const handleOnDrop = async (files: File[]) => {
    const file = files[0];

    const result = await uploadAttachmentMutation({ variables: { input: { file, guideId } } });
    const createdFile = result.data?.guideUploadAttachmentFile?.attachmentFile;
    if (createdFile) {
      addFile(createdFile);
    }
  };

  return (
    <div>
      <FileChooser disabled={isUploading} onDrop={handleOnDrop} />
      {isUploading && <LinearProgress sx={{ mt: 2 }} variant="indeterminate" />}
    </div>
  );
};

const StyledDropzone = styled.div`
  padding: 0 20px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
`;

const FileChooser: FC<{ disabled?: boolean; accept?: Accept; onDrop: (newFiles: File[]) => void }> = ({ disabled, onDrop, accept }) => {
  return (
    <Dropzone disabled={disabled} onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <p>Dra og slipp fil her, eller klikk for å velge</p>
        </StyledDropzone>
      )}
    </Dropzone>
  );
};
