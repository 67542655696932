import React, { FC } from 'react';
import { AttachFile } from '@mui/icons-material';
import { MenuButton, useRichTextEditorContext } from 'mui-tiptap';
import { useModal } from '../../../../../dialogs/useModal';
import { GuideAttachmentsDialog, GuideAttachmentsDialogProps, GuideAttachmentsDialogResult } from '../../../attachments/GuideAttachmentsDialog';

export const AddAttachmentLinkToolbarButton: FC = () => {
  const editor = useRichTextEditorContext();
  const modal = useModal<GuideAttachmentsDialogProps, GuideAttachmentsDialogResult>({ data: {} });

  const handleOnClick = async () => {
    if (!editor) return;
    await modal.open();
  };
  if (!editor) return null;
  return (
    <>
      {modal.isOpen && <GuideAttachmentsDialog {...modal} />}
      <MenuButton disabled={!editor?.isEditable} IconComponent={AttachFile} tooltipLabel="Vedlegg" onClick={handleOnClick}></MenuButton>
    </>
  );
};
