import React, { FC } from 'react';
import { Modal } from '../../../dialogs/useModal';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AttachmentsContextProvider, useAttachmentsContext } from './AttachmentsContext';
import { UploadGuideAttachment } from './UploadGuideAttachment';
import { getAttachmentFileUrl } from './attachmentHelper';
import { useConfig } from '../../../env';
import { useGuideCmsContext } from '../GuideCmsContext';
import TableHeader from '@tiptap/extension-table-header';
import { InsertDriveFile } from '@mui/icons-material';
import { FileIcon } from '../../Uploader';

export interface GuideAttachmentsDialogResult {}

export interface GuideAttachmentsDialogProps {
  editFigureId?: string;
}

export const GuideAttachmentsDialog: FC<Modal<GuideAttachmentsDialogProps, GuideAttachmentsDialogResult>> = (modal) => {
  return (
    <AttachmentsContextProvider modal={modal}>
      <GuideFigureDialogInner />
    </AttachmentsContextProvider>
  );
};

const GuideFigureDialogInner: FC = () => {
  const { attachments, closeModal } = useAttachmentsContext();
  const config = useConfig();
  const { guideId } = useGuideCmsContext();

  const handleOnClose = () => {
    closeModal();
  };

  return (
    <Dialog open={true} maxWidth="md" fullWidth={true} onClose={handleOnClose}>
      <DialogTitle>Legg til nytt vedlegg</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <UploadGuideAttachment />

          <Typography variant="h6">Vedlegg i anvisningen</Typography>
          {attachments.length === 0 && <Alert severity="info">Det finnes ingen vedlegg i anvisningen</Alert>}
          {attachments.length > 0 && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Tekst i dokumentet</TableCell>
                  <TableCell>Filnavn</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attachments.map((attachment) => (
                  <TableRow key={attachment.file.id}>
                    <TableCell>{attachment.text}</TableCell>
                    <TableCell>
                      <Stack direction={'row'} alignItems="center" gap={1}>
                        <FileIcon filename={attachment.file.filename ?? ''} />
                        <Link href={getAttachmentFileUrl(config, guideId, attachment.file)} target="_blank">
                          {attachment.file.filename}
                        </Link>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
};
