import { mergeAttributes, Mark } from '@tiptap/react';

export interface AttachmentOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    insertAttachment: {
      insertAttachment: (options: { attachmentId: string; filename: string }) => ReturnType;
    };
    toggleAttachment: {
      toggleAttachment: (options: { attachmentId: string; filename: string }) => ReturnType;
    };
  }
}

export default Mark.create<AttachmentOptions>({
  name: 'attachment',
  exitable: true,
  keepOnSplit: false,
  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      attachmentId: {
        default: null,
      },
      filename: {
        default: null,
      },
    };
  },

  renderHTML({ mark, HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(HTMLAttributes, {
        'data-attachment-id': mark.attrs.attachmentId,
        'data-filename': mark.attrs.filename,
      }),
      0,
    ];
  },

  addCommands() {
    return {
      insertAttachment:
        (options: { attachmentId: string; filename: string }) =>
        ({ commands }) => {
          return commands.insertContent({
            type: 'text',
            text: options.filename ?? 'Vedlegg',
            marks: [
              {
                type: this.name,
                attrs: {
                  attachmentId: options.attachmentId,
                  filename: options.filename,
                },
              },
            ],
          });
        },
      toggleAttachment:
        (options: { attachmentId: string; filename: string }) =>
        ({ commands }) => {
          return commands.toggleMark(this.name, options);
        },
    };
  },
});
