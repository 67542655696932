import { Box } from '@mui/material';
import { ControlledBubbleMenu, MenuControlsContainer, useRichTextEditorContext } from 'mui-tiptap';
import React, { FC } from 'react';
import { AddStandardLinkToolbarButton } from './toolbar/AddStandardLinkToolbarButton';
import { AddGuideLinkToolbarButton } from './toolbar/AddGuideLinkToolbarButton';
import { AddInternalLinkToolbarButton } from './toolbar/AddInternalLinkToolbarButton';
import { theme } from '../../../../theme';
import { AddCommentToolbarButton } from './toolbar/AddCommentToolbarButton';
import { useFeatureFlags } from '../../../../unleash';

export const TextBubbleMenu: FC<{ guideId: string }> = ({ guideId }) => {
  const editor = useRichTextEditorContext();
  const { cmsComments } = useFeatureFlags();

  if (!editor) return null;
  const isOpen = !editor.view.state.selection.empty && !editor.isActive('link');
  return (
    <ControlledBubbleMenu editor={editor} open={isOpen} sx={{ zIndex: '1000 !important' }}>
      <Box sx={{ padding: theme.spacing(0.5, 1) }}>
        <MenuControlsContainer>
          <AddStandardLinkToolbarButton />
          <AddGuideLinkToolbarButton guideId={guideId} />
          <AddInternalLinkToolbarButton />
          {cmsComments && <AddCommentToolbarButton />}
        </MenuControlsContainer>
      </Box>
    </ControlledBubbleMenu>
  );
};
