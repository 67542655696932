import React, { FC, useEffect } from 'react';
import { useGuideCmsContext } from '../GuideCmsContext';
import { GuideAttachmentsDialogProps, GuideAttachmentsDialogResult } from './GuideAttachmentsDialog';
import { Modal } from '../../../dialogs/useModal';
import { AttachmentFile, AttachmentNode, getAttachments } from './attachmentHelper';

interface AttachmentsContext {
  closeModal: () => void;
  attachments: AttachmentNode[];
  addFile: (file: AttachmentFile) => void;
}

const AttachmentsContextInstance = React.createContext<AttachmentsContext | undefined>(undefined);

export const useAttachmentsContext = (): AttachmentsContext => {
  const context = React.useContext(AttachmentsContextInstance);
  if (!context) {
    throw new Error('useAttachmentsContext must be used within a AttachmentsContextProvider');
  }
  return context;
};

export const AttachmentsContextProvider: FC<{
  modal: Modal<GuideAttachmentsDialogProps, GuideAttachmentsDialogResult>;
  children: React.ReactNode;
}> = ({ modal, children }) => {
  const { editor } = useGuideCmsContext();
  const [attachments, setAttachments] = React.useState<AttachmentNode[]>([]);
  const addFile = (file: AttachmentFile) => {
    if (file.id && file.filename) {
      editor?.commands.insertAttachment({ attachmentId: file.id, filename: file.filename });
      modal.close();
    }
  };

  useEffect(() => {
    if (!editor) {
      return;
    }
    const attachments = getAttachments(editor);
    setAttachments(attachments);
  }, [editor]);

  return (
    <AttachmentsContextInstance.Provider
      value={{
        attachments,
        closeModal: () => modal.close(),
        addFile,
      }}>
      {children}
    </AttachmentsContextInstance.Provider>
  );
};
