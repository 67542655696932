import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import Italic from '@tiptap/extension-italic';
import Bold from '@tiptap/extension-bold';
import Underline from '@tiptap/extension-underline';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import BulletList from '@tiptap/extension-bullet-list';

import Gapcursor from '@tiptap/extension-gapcursor';
import Dropcursor from '@tiptap/extension-dropcursor';
import UniqueID from '@tiptap-pro/extension-unique-id';

import Link from '@tiptap/extension-link';
import HardBreak from '@tiptap/extension-hard-break';

import ListItem from '@tiptap/extension-list-item';

import TableRow from '@tiptap/extension-table-row';
import Highlight from '@tiptap/extension-highlight';

import Mathematics from '@tiptap-pro/extension-mathematics';
import { LinkBubbleMenuHandler } from 'mui-tiptap';
import Collaboration, { isChangeOrigin } from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import CollaborationHistory from '@tiptap-pro/extension-collaboration-history';
import { SnapshotCompare } from '@tiptap-pro/extension-snapshot-compare';

import { TiptapCollabProvider } from '@hocuspocus/provider';
import { IdTokenClaims } from 'oidc-client-ts';
import { GuideLinkExtensionRenderOptions, createGuideLinkExtension } from './GuideLink';
import { createUserFromProfile } from '../../../tiptapCollab/collaborationUser';
import { createImageFigureExtension } from './ImageFigure';
import { Extensions } from '@tiptap/core';
import { NodeViewProps } from '@tiptap/react';
import { FC } from 'react';
import { InternalLinkExtensionRenderOptions, createInternalLinkExtension } from './InternalLink';
import { createTableFigureExtension } from './TableFigure';
import { Figcaption } from './Figcaption';
import { createHeadingExtension } from './Heading';
import { createAppExtension } from './App';
import { createChartExtension } from './Chart';
import { createChartFigureExtension } from './ChartFigure';
import { CustomTableCell, CustomTableHeader } from './TableCell';
import { createMathTypeExtension } from './MathType';
import { createStandardLinkExtension, StandardLinkExtensionRenderOptions } from './StandardLink';
import { Table } from './Table';
import { CustomOrderedList } from './OrderedList';
import History from '@tiptap/extension-history';
import { CommentsKit } from '@tiptap-pro/extension-comments';
import Attachment from './Attachment';

const CustomLinkExtension = Link.extend({
  inclusive: false,
});

const CustomSubscript = Subscript.extend({
  excludes: 'superscript',
});
const CustomSuperscript = Superscript.extend({
  excludes: 'subscript',
});

interface ExtensionOptions {
  rendering: ExtensionRenderOptions;
  comments?: CommentsOptions;
  profile?: IdTokenClaims;
  provider?: TiptapCollabProvider;
}

interface ExtensionRenderOptions {
  appView: FC<NodeViewProps>;
  imageFigureView: FC<NodeViewProps>;
  tableFigureView: FC<NodeViewProps>;
  headingView: FC<NodeViewProps>;
  guideLink: GuideLinkExtensionRenderOptions;
  standardLink: StandardLinkExtensionRenderOptions;
  internalLink: InternalLinkExtensionRenderOptions;
  chartFigureView: FC<NodeViewProps>;
  chartView: FC<NodeViewProps>;
  mathTypeView: FC<NodeViewProps>;
}

interface CommentsOptions {
  onClickThread: (id: string | null) => void;
}

const getBaseExtensions = (options: ExtensionRenderOptions): Extensions => [
  Document,
  Text,
  Italic,
  Bold,
  Underline,
  Highlight,
  Gapcursor,
  Dropcursor.configure({
    width: 3,
    color: '#3B86CB',
  }),
  HardBreak,
  ListItem,
  TextAlign.configure({
    types: ['paragraph'],
  }),
  BulletList,
  CustomOrderedList,
  Paragraph,
  createHeadingExtension(options.headingView),
  createGuideLinkExtension(options.guideLink ?? {}),
  createStandardLinkExtension(options.standardLink ?? {}),
  createImageFigureExtension(options.imageFigureView),
  createAppExtension(options.appView),
  createChartExtension(options.chartView),
  createChartFigureExtension(options.chartFigureView),
  Table,
  createTableFigureExtension(options.tableFigureView),
  createInternalLinkExtension(options.internalLink ?? {}),
  createMathTypeExtension(options.mathTypeView),
  Attachment,
  CustomTableHeader,
  CustomTableCell,
  TableRow,
  Figcaption,
  CustomSubscript,
  CustomSuperscript,
  Mathematics,
  CustomLinkExtension.configure({
    autolink: true,
    linkOnPaste: true,
    openOnClick: false,
  }),
  LinkBubbleMenuHandler,
  UniqueID.configure({
    attributeName: 'id',
    types: ['paragraph', 'heading', 'tableFigure', 'imageFigure', 'chartFigure', 'bulletList', 'orderedList', 'app'],
    filterTransaction: (transaction) => {
      // Filter out transactions that should not generate new IDs
      // - When the setContent command is called (meta.preventUpdate: true), as this will already have IDs
      // - When the transaction is from another user in collab mode
      return !transaction.getMeta('preventUpdate') && !isChangeOrigin(transaction);
    },
  }),
];

export const getExtensions = (options: ExtensionOptions): Extensions => {
  const { provider, profile, rendering, comments } = options;
  if (!provider) {
    return [...getBaseExtensions(rendering), History];
  }
  const user = createUserFromProfile(profile);
  const collabExtensions = [
    Collaboration.configure({
      document: provider.document,
    }),
    CollaborationCursor.configure({
      provider: provider,
      user,
    }),
    CollaborationHistory.configure({
      provider: provider,
    }),
    CommentsKit.configure({
      provider: provider,
      useLegacyWrapping: false,
      onClickThread(id) {
        if (comments?.onClickThread) {
          comments.onClickThread(id);
        }
      },
    }),
  ];
  return [...getBaseExtensions(rendering), ...collabExtensions];
};

export const getExtensionsForPreview = (options: ExtensionOptions): Extensions => {
  const { provider, rendering } = options;
  return [
    ...getBaseExtensions(rendering),
    CommentsKit,
    SnapshotCompare.configure({
      provider,
    }),
  ];
};

export default getExtensions;
