import { UploadGuideAttachmentFileMutation } from '../../../__generated__/graphql';
import { Unpacked } from '../../../graphQLTypes/types';
import { Editor } from '@tiptap/core';
import { Config } from '../../../env';
import { getTextsWithMarks } from '../tiptap/tiptapHelper';

export type AttachmentFile = NonNullable<Unpacked<UploadGuideAttachmentFileMutation>['guideUploadAttachmentFile']['attachmentFile']>;

export const getAttachmentFileUrl = (config: Config | undefined, guideId: string, file: AttachmentFile) => {
  if (!config) {
    return undefined;
  }
  return `${config.apiUrl}/guides/${guideId}/attachments/${file.id}/${file.filename}`;
};

export interface AttachmentNode {
  text: string;
  file: AttachmentFile;
}

export const getAttachments = (editor: Editor): AttachmentNode[] => {
  const attachmentsNodes = getTextsWithMarks(editor.getJSON(), 'attachment');
  return attachmentsNodes.map((node) => {
    const attachmentMark = node.marks?.find((mark) => mark.type === 'attachment');
    const f = {
      text: node.text ?? attachmentMark?.attrs?.filename ?? '',
      file: {
        id: attachmentMark?.attrs?.attachmentId ?? '',
        filename: attachmentMark?.attrs?.filename ?? '',
      },
    };

    return f;
  });
};
